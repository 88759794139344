import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div className="floatLeft">
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
        </ul>        
      </nav>
    </div>
    <div className="floatClear"></div>
    <div className="floatRight"><Link to="/">{siteTitle}</Link></div>    
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `brianstewart.io`,
}

export default Header
